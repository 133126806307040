var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-7 py-8"},[_c('div',{staticClass:"heading"},[_vm._v("For Parents")]),_c('v-data-table',{staticClass:"elevation-8",attrs:{"fixed-header":"","hide-default-footer":"","headers":_vm.headers,"items":_vm.itemsParent,"loading":_vm.loading,"loading-text":"Loading... Please wait","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',{staticClass:"py-2"},[_c('div',{staticClass:"tableItemHead"},[_vm._v(_vm._s(props.item.email_title))]),_c('div',{staticClass:"tableItemSubhead"},[_vm._v(" "+_vm._s(props.item.email_description)+" ")])]),_c('td',{staticClass:"text-center"},[(props.item.user_type=='admin')?_c('v-chip',{staticClass:"actionColumn-btn",on:{"click":function($event){return _vm.route(props.item.id)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"icons",attrs:{"dark":"","color":"#6B6B6B","size":"25"}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Edit")])])],1):_vm._e()],1)])]}}])}),_c('div',{staticClass:"heading"},[_vm._v("For Teachers")]),_c('v-data-table',{staticClass:"elevation-8",attrs:{"fixed-header":"","hide-default-footer":"","headers":_vm.headers,"items":_vm.itemsTeacher,"loading":_vm.loading,"loading-text":"Loading... Please wait","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',{staticClass:"py-2"},[_c('div',{staticClass:"tableItemHead"},[_vm._v(_vm._s(props.item.email_title))]),_c('div',{staticClass:"tableItemSubhead"},[_vm._v(" "+_vm._s(props.item.email_description)+" ")])]),_c('td',{staticClass:"text-center"},[(props.item.user_type=='admin')?_c('v-chip',{staticClass:"actionColumn-btn",on:{"click":function($event){return _vm.route(props.item.id)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"icons",attrs:{"dark":"","color":"#6B6B6B","size":"25"}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Edit")])])],1):_vm._e()],1)])]}}])}),_c('div',{staticClass:"heading"},[_vm._v("For Schools")]),_c('v-data-table',{staticClass:"elevation-8",attrs:{"fixed-header":"","hide-default-footer":"","headers":_vm.headers,"items":_vm.itemsSchool,"loading":_vm.loading,"loading-text":"Loading... Please wait","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',{staticClass:"py-2"},[_c('div',{staticClass:"tableItemHead"},[_vm._v(_vm._s(props.item.email_title))]),_c('div',{staticClass:"tableItemSubhead"},[_vm._v(" "+_vm._s(props.item.email_description)+" ")])]),_c('td',{staticClass:"text-center"},[(props.item.user_type=='admin')?_c('v-chip',{staticClass:"actionColumn-btn",on:{"click":function($event){return _vm.route(props.item.id)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"icons",attrs:{"dark":"","color":"#6B6B6B","size":"25"}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Edit")])])],1):_vm._e()],1)])]}}])}),_c('div',{staticClass:"heading"},[_vm._v("For Districts")]),_c('v-data-table',{staticClass:"elevation-8",attrs:{"fixed-header":"","hide-default-footer":"","headers":_vm.headers,"items":_vm.itemsDistrict,"loading":_vm.loading,"loading-text":"Loading... Please wait","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',{staticClass:"py-2"},[_c('div',{staticClass:"tableItemHead"},[_vm._v(_vm._s(props.item.email_title))]),_c('div',{staticClass:"tableItemSubhead"},[_vm._v(" "+_vm._s(props.item.email_description)+" ")])]),_c('td',{staticClass:"text-center"},[(props.item.user_type=='admin')?_c('v-chip',{staticClass:"actionColumn-btn",on:{"click":function($event){return _vm.route(props.item.id)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"icons",attrs:{"dark":"","color":"#6B6B6B","size":"25"}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Edit")])])],1):_vm._e()],1)])]}}])}),_c('div',{staticClass:"heading"},[_vm._v("For Donors")]),_c('v-data-table',{staticClass:"elevation-8",attrs:{"fixed-header":"","hide-default-footer":"","headers":_vm.headers,"items":_vm.itemsDonor,"loading":_vm.loading,"loading-text":"Loading... Please wait","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',{staticClass:"py-2"},[_c('div',{staticClass:"tableItemHead"},[_vm._v(_vm._s(props.item.email_title))]),_c('div',{staticClass:"tableItemSubhead"},[_vm._v(" "+_vm._s(props.item.email_description)+" ")])]),_c('td',{staticClass:"text-center"},[(props.item.user_type=='admin')?_c('v-chip',{staticClass:"actionColumn-btn",on:{"click":function($event){return _vm.route(props.item.id)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"icons",attrs:{"dark":"","color":"#6B6B6B","size":"25"}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Edit")])])],1):_vm._e()],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }